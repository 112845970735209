/** @jsxImportSource react */
import { PropsWithChildren, useState } from 'react'
import FontAwesomeIcon from './FontAwesomeIcon'

export interface Props {
  title: string
}

export default function FooterMenuList(props: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false)
  const state = open ? 'open' : 'closed'
  const id = props.title.replace(/\s+/g, '-').toLowerCase()
  const buttonId = `footer-${id}`
  const panelId = `footer-${id}-panel`

  return (
    <div data-state={state} className="u-border-b u-border-night tablet:u-border-0">
      <h3 className="u-font-semibold u-leading-[22px] u-tracking-[0.5px] u-text-white tablet:u-text-lg">
        <button
          id={buttonId}
          aria-expanded={open}
          aria-controls={panelId}
          className="u-flex u-h-[48px] u-w-full u-items-center u-justify-between tablet:u-hidden"
          onClick={() => setOpen(!open)}
        >
          {props.title}
          <FontAwesomeIcon
            icon={open ? 'chevron-up' : 'chevron-down'}
            className="u-h-[14px] u-w-[14px] tablet:u-hidden"
          />
        </button>
        <div className="u-hidden tablet:u-block">{props.title}</div>
      </h3>
      <ul role="list" id={panelId} aria-labelledby={buttonId} className="l-stack p-sm u-mt-2 u-pb-4">
        {props.children}
      </ul>
    </div>
  )
}
